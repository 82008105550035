$(function () {
  //重要なお知らせ
  const listItems = $(".js-announcement-list li").length;
  $(".js-announcement-list").each(function () {
    let num = 1,
      closeNum = num - 1;
    $(this).parent().find(".js-btn-more").show();
    $(this).parent().find(".js-btn-close").hide();
    $(this)
      .parent()
      .find("li:not(:lt(" + num + "))")
      .hide();
    $(".js-btn-more").on("click", function () {
      num += 5;
      $(this)
        .parent()
        .find("li:lt(" + num + ")")
        .slideDown();
      if (listItems <= num) {
        $(".js-btn-more").hide();
        $(".js-btn-close").show();
        $(".js-btn-close").on("click", function () {
          $(this)
            .parent()
            .find("li:gt(" + closeNum + ")")
            .slideUp();
          $(this).hide();
          $(".js-btn-more").show();
        });
      }
    });
  });

  // hero
  $(".hero-slide")
    .on("init", function () {
      $('.slick-slide[data-slick-index="0"]').addClass("slick-animation");
    })
    .slick({
      autoplay: true,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 2000,
      autoplaySpeed: 2000,
      pauseOnFocus: false,
      pauseOnHover: false,
    })
    .on({
      beforeChange: function (event, slick, currentSlide, nextSlide) {
        $(".slick-slide", this).eq(nextSlide).addClass("slick-animation");
        $(".slick-slide", this).eq(currentSlide).addClass("stop-animation");
      },
      afterChange: function () {
        $(".stop-animation", this).removeClass("stop-animation slick-animation");
      },
    });

  // slider
  $(".pickup-slider").slick({
    arrows: true,
    autoplay: true,
    adaptiveHeight: true,
    dots: true,
    slidesToShow: 2,
    centerMode: true,
    prevArrow: '<button class="arrow-prev icon arrow-base-right base"></button>',
    nextArrow: '<button class="arrow-next icon arrow-base-right base"></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});
